import React from 'react';
import { WideGlobalHeaderPresenter } from 'js/components/common/layout/Header/WideGlobalHeader/presenter';
import { useGlobalMenuList } from 'js/components/common/layout/Header/WideGlobalHeader/hooks/useGlobalMenuList';
import { useMyMenuProps } from 'js/components/common/layout/Header/WideGlobalHeader/hooks/useMyMenuProps';
import { useUserInfo } from 'js/hooks/useUserInfo';
import { useUserStatus } from 'js/components/common/layout/Header/WideGlobalHeader/hooks/useUserStatus';

type Props = {
  isSimpleHeader: boolean;
  isResponsive: boolean;
  isLogoH1: boolean;
  signupUrl: string;
  searchWord: string;
};

export const WideGlobalHeader: React.FC<Props> = ({
  isSimpleHeader,
  isResponsive,
  isLogoH1,
  signupUrl,
  searchWord,
}) => {
  const { globalMenuList } = useGlobalMenuList();
  const { myMenuProps } = useMyMenuProps();
  const { isLoading } = useUserInfo();
  const { userStatus } = useUserStatus();

  if (isSimpleHeader) {
    return <WideGlobalHeaderPresenter {...{ isLoading }} />;
  }

  return (
    <WideGlobalHeaderPresenter
      {...{
        globalMenuList,
        myMenuProps,
        userStatus,
        isLoading,
        isSimpleHeader,
        isResponsive,
        isLogoH1,
        signupUrl,
        searchWord,
      }}
    />
  );
};
