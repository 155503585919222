import React from 'react';
import { SecondaryContentsWindow } from 'js/components/common/layout/Header/SecondaryContentsWindow';
import { DailyContentsTitle } from 'js/components/common/layout/DailyContents/DailyContentsTitle';
import {
  Props as DailyContentsListProps,
  DailyContentsList,
} from 'js/components/common/layout/DailyContents/DailyContentsList';
import { DailyContentsSkeletonList } from 'js/components/common/layout/DailyContents/DailyContentsSkeletonList';
import { DAILY_CONTENTS_COUNT_PC } from 'js/components/common/layout/DailyContents/DailyContentsSkeletonList/const';
import { RankingPickup } from 'js/components/common/layout/RankingPickup';
import { PC_RANKING_PICKUP_CATEGORIES } from 'js/const/ranking';

export type Props = {
  isOpen: boolean;
  isLogin: boolean | undefined;
  dailyContents: DailyContentsListProps['list'];
  playedCount: number;
};

export const DailyContentsSecondaryWindowPresenter: React.FC<Props> = ({
  isOpen,
  isLogin,
  dailyContents,
  playedCount,
}) => {
  return (
    <SecondaryContentsWindow {...{ isOpen }}>
      <div className="daily-contents-window">
        <div className="daily-contents-window__wrapper">
          <DailyContentsTitle
            playedCount={playedCount}
            contentsCount={dailyContents.length}
            isLogin={isLogin}
          />
          {!!dailyContents.length ? (
            <DailyContentsList list={dailyContents} column={6} />
          ) : (
            <DailyContentsSkeletonList
              count={DAILY_CONTENTS_COUNT_PC}
              column={6}
            />
          )}
        </div>
        <RankingPickup
          categories={PC_RANKING_PICKUP_CATEGORIES}
          isShow={isOpen}
        />
      </div>
    </SecondaryContentsWindow>
  );
};
