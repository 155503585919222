import React from 'react';
import 'css/common/global/layout/_base.scss';
import {
  LayoutPresenter,
  Props as PresenterProps,
} from 'js/components/template/layout/presenter';
import { useIsLoginFromCookie } from 'js/hooks/pages/useIsLoginFromCookie';

type Props = Omit<PresenterProps, 'isLogin'>;

export const Layout: React.FC<Props> = ({
  children,
  isBlankLayout,
  isSimpleHeader,
  isAdDisplayed,
  isAdDisplayedGlobalHeader,
  isAdDisplayedGlobalFooter,
  baseTarget,
  hasBackground,
  isResponsive,
  hasMainContentsWrapper,
  topicPath,
  pageTitle,
  isLogoH1,
  signupUrl,
  searchWord,
  globalHeaderBanner,
  globalFooterBanner,
  isReady,
  importantInformationList,
  readImportantInformation,
  additionalMetaTags,
}) => {
  const { isLogin } = useIsLoginFromCookie();
  return (
    <LayoutPresenter
      isBlankLayout={isBlankLayout}
      isSimpleHeader={isSimpleHeader}
      isAdDisplayed={isAdDisplayed}
      isAdDisplayedGlobalHeader={isAdDisplayedGlobalHeader}
      isAdDisplayedGlobalFooter={isAdDisplayedGlobalFooter}
      baseTarget={baseTarget}
      hasBackground={hasBackground}
      isResponsive={isResponsive}
      hasMainContentsWrapper={hasMainContentsWrapper}
      topicPath={topicPath}
      pageTitle={pageTitle}
      isLogoH1={isLogoH1}
      signupUrl={signupUrl}
      searchWord={searchWord}
      globalHeaderBanner={globalHeaderBanner}
      globalFooterBanner={globalFooterBanner}
      isReady={isReady}
      isLogin={isLogin}
      importantInformationList={importantInformationList}
      readImportantInformation={readImportantInformation}
      additionalMetaTags={additionalMetaTags}
    >
      {children}
    </LayoutPresenter>
  );
};
