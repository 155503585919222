import React from 'react';
import classNames from 'classnames';
import { NextSeo, NextSeoProps } from 'next-seo';
import 'css/common/global/layout/_base.scss';
import { AdvertisementCommonHead } from 'js/components/common/layout/Tag/AdvertisementCommonHead';
import { ServerEnvironment } from 'js/components/common/layout/ServerEnvironment';
import { useTimeTravelParam } from 'js/hooks/useTimeTravelParam';
import { useSetTimestamp } from 'js/hooks/pages/layout/useSetTimestamp';
import { InformationList } from 'js/components/specific/top/Information/InformationList';
import { InformationElementType } from 'js/components/specific/top/Information/InformationList/type';
import { useInformationList } from 'js/hooks/pages/useInformationList';
import { HeaderBanner } from 'js/components/common/layout/Banner/HeaderBanner';
import { FooterBanner } from 'js/components/common/layout/Banner/FooterBanner';
import { WideGlobalFooter } from 'js/components/common/layout/Footer/WideGlobalFooter';
import { WideGlobalHeader } from 'js/components/common/layout/Header/WideGlobalHeader';
import { useAddDeviceViewClassToBody } from 'js/components/template/layout/hooks/useAddDeviceViewClassToBody';
import { ENVIRONMENTS } from 'js/const/env/env';
import { TopicPath } from 'js/components/common/layout/TopicPath';
import { TopicPath as TopicPathType } from 'js/components/common/layout/TopicPath/type';

export type Props = {
  children: React.ReactNode;
  isBlankLayout?: boolean;
  isSimpleHeader?: boolean;
  isAdDisplayed?: boolean;
  isAdDisplayedGlobalHeader?: boolean;
  isAdDisplayedGlobalFooter?: boolean;
  baseTarget?: string;
  hasBackground?: boolean;
  isResponsive?: boolean;
  hasMainContentsWrapper?: boolean;
  topicPath?: TopicPathType[];
  pageTitle?: string;
  isLogoH1?: boolean;
  signupUrl?: string;
  searchWord?: string;
  globalHeaderBanner?: string;
  globalFooterBanner?: string;
  isReady: boolean;
  importantInformationList?: InformationElementType[];
  readImportantInformation?: (id: string) => void;
  isLogin: boolean | undefined;
  additionalMetaTags?: NextSeoProps['additionalMetaTags'];
};

export const LayoutPresenter: React.FC<Props> = ({
  children,
  isBlankLayout = false,
  isSimpleHeader = false,
  isAdDisplayed = true,
  isAdDisplayedGlobalHeader = true,
  isAdDisplayedGlobalFooter = true,
  baseTarget,
  hasBackground = false,
  isResponsive = false,
  hasMainContentsWrapper = true,
  topicPath,
  pageTitle,
  isLogoH1,
  signupUrl = '/signup/',
  searchWord = '',
  globalHeaderBanner,
  globalFooterBanner,
  isReady,
  importantInformationList,
  readImportantInformation,
  isLogin,
  additionalMetaTags = [],
}) => {
  const informationList = useInformationList();
  const timeTravelParam = useTimeTravelParam();
  useSetTimestamp();
  useAddDeviceViewClassToBody('view-pc');

  const mainContents = (
    <>
      <HeaderBanner
        isAdDisplayed={isAdDisplayed && isAdDisplayedGlobalHeader}
        isSimpleHeader={isSimpleHeader}
        globalHeaderBanner={globalHeaderBanner}
      />
      {pageTitle && <h1 className="c_title_1">{pageTitle}</h1>}
      {children}
      {topicPath && <TopicPath topicPath={topicPath} />}
    </>
  );

  return (
    <div
      className="outermost-wrapper view-pc"
      data-testid="TestId__TOP_PAGE_CONTAINER"
      style={{
        display: isReady ? '' : 'none',
      }}
    >
      <NextSeo
        additionalMetaTags={[
          {
            name: 'viewport',
            content: '',
          },
          ...additionalMetaTags,
        ]}
      />
      {!isBlankLayout && isAdDisplayed && <AdvertisementCommonHead />}
      {baseTarget && <base target={baseTarget} />}
      <div className="c_body">
        {/* こっちはjs・cookie無効 */}
        <InformationList list={informationList} />
        {ENVIRONMENTS.PRODUCT_ENV !== 'prod' && (
          <ServerEnvironment
            env={ENVIRONMENTS.PRODUCT_ENV}
            personalEnv={ENVIRONMENTS.PERSONAL_ENV}
            requestTime={
              timeTravelParam ? new Date(timeTravelParam) : undefined
            }
          />
        )}
        <WideGlobalHeader
          {...{ isSimpleHeader, isResponsive, isLogoH1, signupUrl, searchWord }}
        />
        <div
          className={classNames('c_main-contents', {
            'has-background': hasBackground,
            'is-responsive': isResponsive,
          })}
        >
          {/* こっちは重要なお知らせ */}
          {isLogin && !!importantInformationList?.length && (
            <InformationList
              className="important-information"
              list={importantInformationList}
              readInformation={readImportantInformation}
            />
          )}
          {hasMainContentsWrapper ? (
            <div className="c_main-contents__wrapper">{mainContents}</div>
          ) : (
            mainContents
          )}
        </div>
        <FooterBanner
          isAdDisplayed={isAdDisplayed && isAdDisplayedGlobalFooter}
          globalFooterBanner={globalFooterBanner}
        />
        <WideGlobalFooter {...{ isLogin }} />
      </div>
    </div>
  );
};
