import React from 'react';
import classNames from 'classnames';
import { Search } from 'js/components/ui/Form/Search';
import { GradeLabel } from 'js/types/grade';
import { GradeIcon } from 'js/components/ui/Icon/GradeIcon';
import { Point } from 'js/components/common/Point/Point';
import { Button } from 'js/components/ui/Button/Button';
import { WideGlobalMenu } from 'js/components/common/layout/Header/WideGlobalMenu';
import {
  Props as WideMyMenuProps,
  WideMyMenu,
} from 'js/components/common/layout/MyMenu/WideMyMenu';
import { GlobalMenuItemType } from 'js/components/common/layout/Header/WideGlobalMenu/type';
import { login } from 'js/utils/login';

export type UserStatusType = {
  gradeLabel: GradeLabel;
  nickname: string;
  totalPoint: number;
};

export type Props = {
  globalMenuList?: GlobalMenuItemType[];
  userStatus?: UserStatusType;
  myMenuProps?: WideMyMenuProps;
  isLogoH1?: boolean;
  signupUrl?: string;
  isResponsive?: boolean;
  isLoading: boolean;
  searchWord?: string;
};

export const WideGlobalHeaderPresenter: React.FC<Props> = ({
  globalMenuList,
  userStatus,
  myMenuProps,
  signupUrl = '/signup/',
  isLogoH1,
  isResponsive,
  isLoading,
  searchWord,
}) => {
  // ロゴ
  const LogoTag = isLogoH1 ? 'h1' : 'div';
  const logoElement = (
    <LogoTag className="global-header__logo">
      {/* TODO: TOPページがNext.js化されたらnext/linkを使う */}
      <a className="global-header__logo-link" href="/">
        <img
          className="global-header__logo-image"
          src="/global/img/logo_ecnavi.svg"
          alt="ポイントサイトはECナビ"
          width="160"
          height="36"
        />
      </a>
    </LogoTag>
  );

  // 検索窓
  const searchElement = (
    <Search
      baseClassName="global-header-search"
      name="word"
      maxLength={100}
      placeholder="サービスを検索"
      action="/search/"
      defaultValue={searchWord}
    />
  );

  // グレードアイコン
  const gradeIconElement = userStatus && (
    <a className="global-header__grade" href="/mypage/">
      <GradeIcon
        className="global-header__grade-icon"
        {...userStatus}
        width={24}
        height={30}
      />
    </a>
  );

  // ニックネーム
  const nicknameElement = userStatus && (
    <a className="global-header__user-name c_reset" href="/mypage/">
      {userStatus.nickname}
      <span className="global-header__user-name-san">さん</span>
    </a>
  );

  // ポイント
  const pointElement = userStatus && (
    <a className="global-header__point c_reset" href="/mypage/point_history/">
      <Point
        className="new-my-menu-point__point"
        point={userStatus.totalPoint}
        size="x-large"
      />
    </a>
  );

  // ユーザーステータス
  const userStatusElement = userStatus && (
    <div
      className="global-header__user-status"
      style={{ visibility: isLoading ? 'hidden' : 'visible' }}
    >
      {gradeIconElement}
      {nicknameElement}
      {pointElement}
    </div>
  );

  // ログイン＆無料会員登録
  const loginAndSignupElement = (
    <div
      className="logout-menu"
      style={{ visibility: isLoading ? 'hidden' : 'visible' }}
    >
      <Button
        className="logout-menu__login"
        size="small"
        width={100}
        callback={(e) => login(e)}
      >
        ログイン
      </Button>
      <Button
        className="logout-menu__signup"
        size="small"
        width={100}
        href={signupUrl}
        isPrimary={true}
      >
        無料会員登録
      </Button>
    </div>
  );

  return (
    <header
      className={classNames(
        'global-header',
        { 'type-simple': !globalMenuList },
        { 'is-responsive': isResponsive },
      )}
    >
      <div className="global-header__wrapper">
        {!globalMenuList ? (
          <div className="global-header__container">{logoElement}</div>
        ) : (
          <>
            <div className="global-header__container">
              {logoElement}
              {searchElement}
              {userStatus ? userStatusElement : loginAndSignupElement}
            </div>
            <div className="global-header__container">
              <WideGlobalMenu list={globalMenuList} />
              {myMenuProps && <WideMyMenu {...myMenuProps} />}
            </div>
          </>
        )}
      </div>
    </header>
  );
};
